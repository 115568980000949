import React, { FunctionComponent } from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import { Container, Grid, Heading } from '@chakra-ui/react'
import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { FlexibleContent } from '../components/flexibleContent/FlexibleContent'
import { WpPage } from '../../graphql-types'

type DataProps = {
  wpPage: WpPage
}

const IndexPage: FunctionComponent<PageProps<DataProps>> = ({ data }) => {
  const {
    wpPage: { flexibleContent, id, title },
  } = data

  return (
    <Layout>
      <SEO title="Home" />
      <Container maxW="4xl">
        <Grid gridGap="32">
          <FlexibleContent {...flexibleContent} />
        </Grid>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query homePage {
    wpPage(id: { eq: "cG9zdDo4Njc=" }) {
      flexibleContent {
        content {
          ... on WpPage_Flexiblecontent_Content_Simple {
            align
            content {
              ... on WpPage_Flexiblecontent_Content_Simple_Content_Paragraph {
                fieldGroupName
                text
              }
              ... on WpPage_Flexiblecontent_Content_Simple_Content_List {
                fieldGroupName
                listItems {
                  fieldGroupName
                  listItem
                }
              }
            }
            fieldGroupName
            heading
            headingStyle
            link
            subheading
            subheadingStyle
            text
          }
          ... on WpPage_Flexiblecontent_Content_Quote {
            fieldGroupName
            quote
            caption
          }
          ... on WpPage_Flexiblecontent_Content_Image {
            fieldGroupName
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_Content_Columns {
            fieldGroupName
            template
            columnOne {
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Simple {
                align
                content {
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Simple_Content_Paragraph {
                    fieldGroupName
                    text
                  }
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Simple_Content_List {
                    fieldGroupName
                    listItems {
                      fieldGroupName
                      listItem
                    }
                  }
                }
                fieldGroupName
                heading
                headingStyle
                link
                subheading
                subheadingStyle
                text
              }
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Quote {
                caption
                fieldGroupName
                quote
              }
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Image {
                fieldGroupName
                image {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            columnOneRow
            columnTwo {
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Simple {
                align
                content {
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Simple_Content_Paragraph {
                    fieldGroupName
                    text
                  }
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Simple_Content_List {
                    fieldGroupName
                    listItems {
                      fieldGroupName
                      listItem
                    }
                  }
                }
                fieldGroupName
                heading
                headingStyle
                link
                subheading
                subheadingStyle
                text
              }
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Quote {
                caption
                fieldGroupName
                quote
              }
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Image {
                fieldGroupName
                image {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            columnTwoRow
          }
        }
      }
      id
      slug
      title
    }
  }
`
